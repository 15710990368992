<template>
  <div class="contacts_container">
    <MemberTitle :memberTitle="'销售合同'"/>
    <div class="contacts_info">
      <div class="title">销售合同</div>
      <div class="bottom"></div>
      <div class="table">
        <el-table :data="contrlar.data" >
          <el-table-column prop="xybh" label="协议编号"  />
          <el-table-column prop="sygsmc" label="商业公司"  />
          <el-table-column prop="lxrname" label="联系人姓名"  />
          <el-table-column prop="phone" label="联系人电话"  />
          <el-table-column prop="mc" label="品种"  />
          <el-table-column prop="xyl" label="协议量"  />
          <el-table-column prop="kpj" label="开票价"  />
          <el-table-column prop="ksrq" label="开始日期"  />
          <el-table-column prop="jsrq" label="截止日期"  />
          <el-table-column prop="contractStateValue" label="合同状态"  />
        </el-table>
      </div>
      <div class="pagenation">
        <el-config-provider :locale="locale">
        <el-pagination layout="prev, pager, next,jumper" @current-change="currentPage" :total="pagination.data.total" v-model="Nowcurrent" ></el-pagination>
        </el-config-provider>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive,onMounted,getCurrentInstance, ref} from 'vue'
import MemberTitle from '../../../components/MemberTitle.vue'
import { ElConfigProvider } from "element-plus"; //设置element中文显示
import zhCn from "element-plus/lib/locale/lang/zh-cn";   //引入elementui plus中文解析包
export default {
  name:'contacts',
  components:{MemberTitle,ElConfigProvider},
  setup(){
    const { proxy } = getCurrentInstance()   //引入原型代理
    const contrlar = reactive({data:[]})   //合同信息
    let locale = zhCn; //设置中文
    const pagination = reactive({data:{
      current:1,
      pageSize:20,
      total:10
    }})
    const Nowcurrent = ref(1)    //点击的当前页
   
     //页数变化
    const currentPage = (current) => {
      pagination.data.current = current
      getContraler()
    }
      //获取销售合同
    const getContraler = () => {
      let param = {}
      param.current = pagination.data.current
      param.pagesize = pagination.data.pageSize
      proxy.$get('v3/member/front/member/getContractInfo',param).then(ret => {
        contrlar.data = ret.data.list
        pagination.data = ret.data.pagination
      })
    }
    onMounted(() => {
      getContraler()
    })
    return{
      contrlar,
      pagination,
      currentPage,
      Nowcurrent,
      locale, //设置elementui plus 中文
      // backCurrent,
      // goCurrent
      }
  }
}
</script>

<style lang="scss" scoped>
.contacts_container{
  padding-top: 20px;
  padding-left: 50px;
  .member_title_breadcrumb{
    width: 1010px;
    float: left;
    border: 1px solid rgba(187, 187, 187, .25);
    margin-left: 20px;
    .el-breadcrumb__inner{
      color: #101010 !important;
    }
  }
  .contacts_info{
    width: 1010px;
    // height: 800px;
    background-color: #fff;
    float: left;
    border: 1px solid rgba(187, 187, 187, .25);
    border-top:0;
    margin-left: 20px;
    .title{
      color: #101010;
      font-size: 18px;
      font-weight: bold;
      font-family: SourceHanSansSC-bold;
      margin-top: 20px;
      margin-left: 58px;

    }
    .bottom{
      width: 909px;
      height: NaNpx;
      border: 1px solid rgba(187, 187, 187, 0.25);
      margin: 0 auto;
      margin-top: 22px;
    }
    /* .table{
      width: 364px;
      border: 1px solid rgba(187, 187, 187, 0.25);
      overflow: scroll;
      margin: 0 auto;
      margin-top: 50px;
    } */
    .table{
      border: 1px solid rgba(187, 187, 187, 0.25);
      margin-top: 30px;
    }
  }
}
.pagenation{
  margin-top: 20px;
}
</style>